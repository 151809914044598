import React, {Component, useState} from "react";
import { useEffect } from "react";
import { Brush, CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis, Surface, Symbols } from "recharts";
import { useCurrentPng } from "recharts-to-png";
// import { renderCustomizedLegend } from "../../../../../services/api/LegendMap";
import CardPresentational from "../../../../Presentational/Sinan/CardPresentational";
import LineContent from "./LineContent";

const colors = ["#E60017", "#FC6D0D", "#B10FFF", "#0C28F2", "#D44D13", "#00FF87", "#FFD700", "#9400D3", "#00BFFF", "#A52A2A", "#FF1493", "#228B22", "#1E90FF"];
const NotificationsByDayContainer = ({data, labels,typeContainer, setTypeContainer, handleClick,changedGraficToChart}) => {
    const [chartDataSets, setChartDataSets] = useState();
    const [getpng, {ref}] = useCurrentPng();
    const [titleElementData, setTitleElementData] = useState([]);
    const [element, setElement] = useState(<></>);

    useEffect(() => {
        if(data){
            setTitleElementData(data);
            buildChartDataset(data)
        }
    }, [data]);

    useEffect(() => {
        if(titleElementData && titleElementData.length > 0){
            setElement(buildChartElement);
        }
    }, [titleElementData, changedGraficToChart])
    
    const renderCustomizedLegend  = () => {
        return (
          <div className="customized-legend">
            {titleElementData.map((ano, index) => {
              const color = colors[index];
              const active = ano.checked;
              const style = {
                marginRight: 10,
                color: active ? "#000" : "#AAA",
                cursor: "pointer",
              };
    
              return (
                <span
                  className="legend-item"
                  onClick={() => handleClick(index).then((response) => {
                    setTitleElementData(response);
                    setElement(buildChartElement);
                  })}
                  style={style}
                >
                  <Surface width={10} height={10} viewBox="0 0 10 10">
                    <Symbols cx={5} cy={5} type="circle" size={50} fill={color} />
                    {!active && (
                      <Symbols
                        cx={5}
                        cy={5}
                        type="circle"
                        size={25}
                        fill={"#FFF"}
                      />
                    )}
                  </Surface>
                  <span>{ano.label}</span>
                </span>
              );
            })}
          </div>
        );
    };

    const getDataSets = async (data) => {
        const dataset = [];
        var maiorValor = 0;

        for(const index in labels){
            const dataSetObject = {
                label: labels[index]
            }
            for(let ano in data){
                if(data[ano][index]){
                    if(data[ano].label){
                        dataSetObject[data[ano].label] = data[ano][index]["notifications"] ;
                    }else{
                        dataSetObject["Notificações"] = data[ano][index]["notifications"];
                    }

                    data[ano].checked = true;
                    setTitleElementData(data)

                    if(parseFloat(data[ano][index]["notifications"]) > parseFloat(maiorValor))
                    {
                        maiorValor = data[ano][index]["notifications"];
                    }
                }
            }
            dataset.push(dataSetObject);
        }

        maiorValor = parseInt(maiorValor);


        return dataset;
        
    }

    async function buildChartDataset(traps){        
        const datasets = await getDataSets(traps);
        setChartDataSets(datasets);
    }

    const lineChartElement = () => {
        return (
            titleElementData.map((ano, index) => {
                return (
                    <Line dataKey={ano.label || "Notificações"} stroke={colors[index]} hide={ano.checked ? false : true}/>
                )
            })
        )
    }

    const buildChartElement = () => {
        return(
            <ResponsiveContainer width="100%" height="100%">
                <LineChart
                    id={"NotificationsLineChartChart"}
                    data={chartDataSets}
                    margin={{
                        top:30,
                        right:15
                    }}
                    ref={ref}
                >
                    <text x={"50%"} y={20} fill="black" textAnchor="middle" dominantBaseline="central">
                        <tspan fontSize="16" fontWeight={"bolder"}>Casos Notificados </tspan>
                    </text>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="label" style={{fontSize:12}}/>
                    <YAxis style={{fontSize:12}} />
                    <Tooltip/>
                    {
                        titleElementData.length != 1
                        ?
                        <Legend 
                            align="center" 
                            verticalAlign="top" 
                            content={() => renderCustomizedLegend()}
                        />
                        :-
                        <Legend align="center" verticalAlign="top" />
                    }
                    {titleElementData.map((ano, index) => {
                        return(
                            <Line
                                dataKey={ano.label || "Notificações"}
                                stroke={colors[index]}
                                hide={ano.checked ? false : true}
                            />
                        )
                    })}
                    <Brush dataKey={"label"}/>
                    <LineContent 
                        titleElementData={titleElementData}
                        colors={colors}
                    />
                </LineChart>
            </ResponsiveContainer>
        );

    }
    const style={
        cardHeaderText: {
            color:"black",
            fontWeight:"bold",
            fontSize:"0.97rem"
        },
    }

    const textCardHeader = "Número de Notificações";
    return(
        <CardPresentational
            CardId="NotificationsLineChart"
            style={style}
            typeContainer={typeContainer}
            setTypeContainer={setTypeContainer}
            textCardHeader={textCardHeader}
            element={buildChartElement()}
            getpng={getpng}
        />
    );
}

export default NotificationsByDayContainer;
import React, { Component } from "react";
import { ARMADILHA_INFO } from "../../../constants/ArmadilhaConstant";
import { timeStampFormated } from "../../../services/utils/format";
import moment from "moment";
import { getTrapRange } from "../../../constants/RangeTrapConstant";
import { daysDifferenceBetweenTodayAndDate } from "../../../services/utils/TrapStatus/trapStatus";

export function withBasicListMap(WrappedComponent) {
  return class extends Component {
    buildMapList(
      traps,
      type,
      color,
      key,
      checked = true,
      typeCheck = false,
      trapTypeName = null,
      changeColor = false,
      organizationId
    ) {
      const trapList = [];

      for (const trap of traps) {
        let daysDifference;
        const trapInstallDate = moment(trap.dateInstalled);
        const trapLastReadDate =
          !trap.lastReadDate || trap.lastReadDate === ""
            ? null
            : moment(trap.lastReadDate);
        
        const daysDifferenceBetweenTodayAndInstallDate =
            daysDifferenceBetweenTodayAndDate(trapInstallDate);
          const daysDifferenceBetweenTodayAndLastReadDate =
            daysDifferenceBetweenTodayAndDate(trapLastReadDate);

        if (daysDifferenceBetweenTodayAndLastReadDate === null)
          daysDifference = daysDifferenceBetweenTodayAndInstallDate;
        else
          daysDifference = Math.min(
            daysDifferenceBetweenTodayAndInstallDate,
            daysDifferenceBetweenTodayAndLastReadDate
          );

        let dateTime = new Date(trap.dateInstalled);

        let name = ARMADILHA_INFO[trap.trapTypeName].apelido;
        if (trap.number) {
          name = name + " Nº " + trap.number;
        }

        name += `\n ${timeStampFormated(dateTime)}`;

        if (
          typeCheck &&
          trapTypeName == ARMADILHA_INFO[trap.trapTypeName].apelido
        ) {
          checked = true;
        }
        const position = [trap.latitude, trap.longitude];
        const icon = color + trap.trapTypeName + "Icon";
        var color = color;

        if (changeColor) {
          const trapTypeRange = getTrapRange(trap.trapTypeName);

          if (trapTypeRange.quantityRange === 3) {
            if (daysDifference <= trapTypeRange.data.middle) color = "green"
            else if (daysDifference <= trapTypeRange.data.end) color = "yellow";
            else color = "red";
          }
          
          if (trapTypeRange.quantityRange === 2) {
            console.log("Trap number:" + trap.number + "a diferenca eh: " + daysDifference);
            if (daysDifference <= trapTypeRange.data.end) color = "green";
            else color = "red";
          }
        }

        const trapListObject = {
          name,
          position,
          draggableTrap: false,
          type,
          number: trap.number,
          incomingIcon: icon,
          intensity: 1,
          icon: ARMADILHA_INFO[trap.trapTypeName].icons[color],
          checkedIcon: ARMADILHA_INFO[trap.trapTypeName].icons["black"],
          organizationId,
        };

        trapList.push(trapListObject);
      }

      const mapListObject = {
        name: key,
        key,
        checked: checked,
        points: trapList,
        value: trapList,
      };

      return mapListObject;
    }

    render() {
      return (
        <WrappedComponent {...this.props} buildMapList={this.buildMapList} />
      );
    }
  };
}
import React, { memo, useEffect, useState } from "react";
import Chart from "chart.js";
import LoadingSpin from "react-loading-spin";
import CryptoJS from 'crypto-js';
// reactstrap components
import {
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import {
  chartOptions,
  parseOptions,
} from "variables/charts.jsx";

// async
// import NotificationsByDayHistoricSerieContainer from "../components/Containers/Sinan/Charts/Line/NotificationsByDayHistoricSerieContainer";
// import NotificationsByDayContainer from "../components/Containers/Sinan/Charts/Line/NotificationsByDayContainer";
// import ConfirmationsByDayHistoricSerieContainer from "../components/Containers/Sinan/Charts/Line/ConfirmationsByDayHistoricSerieContainer";
// import ConfirmationsByDayContainer from "../components/Containers/Sinan/Charts/Line/ConfirmationsByDayContainer";
// import GenderNotificationsDonutContainer from "../components/Containers/Sinan/Charts/Donuts/GenderNotificationsDonutContainer";
// import GenderConfirmedDonutContainer from "../components/Containers/Sinan/Charts/Donuts/GenderConfirmedDonutContainer";
// import SorotypeDonut from "../components/Containers/Sinan/Charts/Donuts/SorotypeDonut";
// import TypeExamDonutContainer from "../components/Containers/Sinan/Charts/Donuts/TypeExamDonutContainer";
// import GenderNotificationsBar from "../components/Containers/Sinan/Charts/VerticalBar/GenderNotificationsBar";
// import GenderConfirmedBar from "../components/Containers/Sinan/Charts/VerticalBar/GenderConfirmedBar";
// import NotificationAgedContainer from "../components/Containers/Sinan/Charts/HorizontalBar/NotificationAgedContainer";
// import ConfirmedAgedContainer from "../components/Containers/Sinan/Charts/HorizontalBar/ConfirmedAgedContainer";
// import ReportingUnityContainer from "../components/Containers/Sinan/Charts/HorizontalBar/ReportingUnityContainer";

import SinanFilterPresentational from "../components/Presentational/Sinan/SinanFilterPresentational";
import ConfirmedMonthContainer from "../components/Containers/Sinan/Cards/ConfirmedMonthContainer";
import ConfirmedWeekContainer from "../components/Containers/Sinan/Cards/ConfirmedWeekContainer";
import getWeeks from "../services/utils/epidemiologicalWeek";
import ConfirmedYearContainer from "../components/Containers/Sinan/Cards/ConfirmedYearContainer";
import NotificationMonthContainer from "../components/Containers/Sinan/Cards/NotificationMonthContainer";
import NotificationWeekContainer from "../components/Containers/Sinan/Cards/NotificationWeekContainer";
import NotificationYearContainer from "../components/Containers/Sinan/Cards/NotificationYearContainer";
import { getNotificationCases, getNotificationNumbersAged, getNotificationsByReportingUnity, getNotificationsGeoRef, getUploadHistory, getUnidadesNotificadoras } from "../services/api/Sinan";

import MainPeriodComponent from "../components/Inputs/MainPeriodComponent";
import "./DashboardSinan.css";
import moment from "moment";
import { buildEpidemiologicalLabels } from "../services/utils/Entomologico/Labels";
import { getEpidemiologicalPeriod } from "../services/utils/Entomologico/Period";
import { getUserData } from "../services/utils/auth";
import { fetchTerritorializations, getAllTypeTerritorializations } from "../services/api/territorialization";
import { getActiveTrapsToMap, getTrapsTypes } from "../services/api/Trap";
import GeographicFilterPresentational from "../components/Presentational/Filters/GeographicFilterPresentational";
import UnitFilter from "../components/Presentational/Filters/UnitFilter";
import ReactTooltip from "react-tooltip";
import { daysBetweenTwoDates, toISOFormat } from "../services/utils/format";
import { getTodayEpidemiologicalWeek } from "../services/utils/todayEpidemiologicalWeek";
import TodayEpidemiologicalWeekComponent from "../components/Containers/Entomologico/todayEpidemiologicalWeek/TodayEpidemiologicalWeekComponent";
import { getVersion } from "../constants/VersionConstant";
import { Suspense } from "react";
import MapLoadingSkeleton from "../components/ui/Loading/MapLoadingSkeleton"
import DonutLoadingSkeleton from "../components/ui/Loading/DonutGraphicLoadingSkeleton";
import PyramidGraphicLoadingSkeleton from "../components/ui/Loading/PyramidGraphicLoadingSkeleton";
import VerticalBarGraphicLoadingSkeleton from "../components/ui/Loading/VerticalBarGraphicLoadingSkeleton";
import HorizontalBarGraphicLoadingSkeleton from "../components/ui/Loading/HorizontalBarGraphicLoadingSkeleton";


const DashboardSinan = (props) => {
  const [typeDateFilter, setTypeDateFilter] = useState("DT_SIN_PRI");
  const [firstFilterAssistance, setFirstFilterAssistance] = useState(false)
  const [firstApplyFilter, setFirstApplyFilter] = useState(false)
  const organizationId = getUserData("organizationId");
  const webVersion = getVersion(organizationId);
  const [trapMapInfo, setTrapMapInfo] = useState(null);
  const [yearsQuantity, setYearsQuantity] = useState(0);
  const [sinanHistory, setSinanHistory] = useState({ uploadDate: '', status: '', diffDays: 0, displayUploadDate: '' });
  const [dataByUnity, setDataByUnity] = useState([]);
  const [dataToChart, setDataToChart] = useState([]);
  const [graficToChart, setGraficToChart] = useState([]);
  const [dataToCard, setDataToCard] = useState([]);
  const [dataAged, setDataAged] = useState([]);
  const [dataGeoRef, setDataGeoRef] = useState([]);
  const [loadingTrapMap, setLoadingTrapMap] = useState(false);
  const [loadingDataGeoRef, setLoadingDataGeoRef] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [loadingGraficData, setLoadingGraficData] = useState(false);
  const [loadingDataByUnit, setLoadingDataByUnit] = useState(false);
  const [loadingDataAged, setLoadingDataAged] = useState(false);
  const [loadingTerritorializationRequest, setLoadingTerritorializationRequest] = useState(true);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [months, setFilterMonths] = useState([]);
  const [year, setFilterYear] = useState([]);
  const [weekValue1, setWeekValue1] = useState([]);
  const [weekValue2, setWeekValue2] = useState([]);
  const [unityOptions, setUnityOptions] = useState([]);
  const [unitySelected, setUnitySelected] = useState([]);
  const [weeks, setFilterWeeks] = useState([]);
  const [territorializationList, setTerritorializationList] = useState([]);
  const [selectedTerritorializations, setSelectedTerritorializations] = useState([]);
  const [selectedDesease, setSelectedDesease] = useState("DENGUE");
  const [isFilterVisible, setFilterVisibility] = useState(true);
  const [chartLabels, setChartLabels] = useState([]);
  const [chartPeriods, setChartPeriods] = useState([]);
  const [graficLabels, setGraficLabels] = useState([]);
  const [graficPeriods, setGraficPeriods] = useState([]);
  const [todayEpidemiologicalWeek, setTodayEpidemiologicalWeek] = useState("1");
  const [typeContainer,setTypeContainer] = useState("serieHistorica");
  const [typeContainerConfirmed,setTypeContainerConfirmed] = useState("serieHistorica");
  const [changedGraficToChart,setChangedGraficToChart] = useState(true);
  const [graficNotificationsYears, setGraficNotificationsYears] = useState([]);
  const versionRelease ="240319";

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  useEffect(() => {
    document.body.classList.add('sinan-container');

    const date = new Date();
    const endDateString = moment(date).format("YYYY-MM-DD");

    const year = [{ label: date.getFullYear().toString(), value: date.getFullYear().toString() }];

    const periods = new Array();

    //Quantidade de anos do gráfico
    setYearsQuantity(13);
    
    date.setDate((date.getDate() - 15));
    
    const startDateString = moment(date).format("YYYY-MM-DD");

    const epidemiologicalWeek = getWeeks().filter(ew => ew.year === year[0].value);

    setTodayEpidemiologicalWeek(getTodayEpidemiologicalWeek());

    setStartDate(startDateString);
    setEndDate(endDateString);
    
    periods.push({ year: year[0].value, periods: epidemiologicalWeek[0].weeks.map((ew) => { return { finalDate: moment(ew.endDate, "DD/MM/YYYY").format("YYYY-MM-DD"), beginDate: moment(ew.beginDate, "DD/MM/YYYY").format("YYYY-MM-DD") } }) });

    //setFilterYear(year);
    setFirstFilterAssistance(true);

    setFirstApplyFilter(true);

    setLoadingTerritorializationRequest(true);

    const reqTypeTerritorializations = getAllTypeTerritorializations();
    reqTypeTerritorializations.then(async typeResponse => {
      const listTerritorialization = new Array();
      for (const type of typeResponse.data) {
        try {
          if (type.typeGeometry === "Polygon") {
            const territorializationRequest = await fetchTerritorializations(type.id);
            listTerritorialization.push({
              name: type.name,
              label: type.name,
              options: territorializationRequest.data.map(territorialization => { return { label: territorialization.name, value: territorialization.id } }),
              territorializationList: territorializationRequest.data
            })
          }
        } catch (error) {
        }
      }
      setTerritorializationList(listTerritorialization);
      setLoadingTerritorializationRequest(false);
    });

  }, []);

  useEffect(() => {
    if (firstFilterAssistance) {
      applyFilter();
    }
  }, [firstFilterAssistance])

  useEffect(() => {
    if (chartLabels.length > 0) {
      fetchData();
    }
  }, [chartLabels]);

  useEffect(() => {
    if (graficLabels.length > 0) {
      fetchData(true);
    }
  }, [graficLabels]);

  async function fetchCardData() {
    const dataOrder = ["7days", "1month", "1year"];
    let yearsToFilter = [];
    let endDateYear = parseInt(moment(endDate).format("YYYY"));
    let yearBeginDate = parseInt(moment(endDate).subtract(1, "years").format("YYYY"));

    //pegando os anos da data de hoje - 1 ano
    for (let i = yearBeginDate; i <= endDateYear; i++) {
      yearsToFilter.push(i.toString())
    }

    const filter = [{
      organizationId: getUserData("organizationId"),
      years: yearsToFilter,
      desease: selectedDesease,
      territorializations: selectedTerritorializations.map(({ value }) => value),
      periods: [
        //período de 7 dias
        {
          beginDate: moment(endDate).subtract(7, "days").format("YYYY-MM-DD"),
          finalDate: endDate,
        },
        //período de 30 dias
        {
          beginDate: moment(endDate).subtract(1, "months").format("YYYY-MM-DD"),
          finalDate: endDate,
        },
        //período de 1 ano
        {
          beginDate: moment(endDate).subtract(1, "years").format("YYYY-MM-DD"),
          finalDate: endDate,
        }
      ]
    }]

    const reqNotificationNumbers = await getNotificationCases(filter);
    const notificationNumbers = reqNotificationNumbers.data;

    for (const index in notificationNumbers[0]) {
      notificationNumbers[0][index].label = dataOrder[index];
    }

    setDataToCard(notificationNumbers[0]);
  }

  function fetchData(isGrafic = false) {
    // Determinar aqui a release, pois mexemos muito no localstorage para facilitar manipulação dos dados já armazenados
    
    if(!isGrafic){
      setLoadingDataAged(true);
      setLoadingDataByUnit(true);
      setLoadingDataGeoRef(true);
      setLoadingTrapMap(true);
    } else{
      setLoadingGraficData(true);
    }

    var version = localStorage.getItem("version");

    if(version == "" || version != versionRelease){
      localStorage.clear();
      localStorage.setItem("version", versionRelease);
    }

    setLoadingData(true);

    let yearsToFilter = [];
    
    fetchCardData()


    if(!isGrafic){
      if ((year && year.length == 0) || !year) {
        let beginDate = parseInt(moment(startDate).format("YYYY"));
        let finalDate = parseInt(moment(endDate).format("YYYY"));
        for (let i = beginDate; i <= finalDate; i++) {
          yearsToFilter.push(i.toString());
        }
      }

      for (const period in chartPeriods) {
        if (year && year.length > 0) {
          chartPeriods[period].years = [year[period].label];
        } else {
          chartPeriods[period].years = yearsToFilter;
        }
        if (unitySelected != null)
          chartPeriods[period].unityFilter = unitySelected.value;
        chartPeriods[period].typeDateFilter = typeDateFilter;
        chartPeriods[period].organizationId = organizationId;
        chartPeriods[period].desease = selectedDesease;
        chartPeriods[period].ageInterval = 5;
        chartPeriods[period].ageMaximum = 85;
        chartPeriods[period].territorializations = selectedTerritorializations.map(({ value }) => value);
      }

      const promises = [
        getNotificationsToChart(chartPeriods),
        getNotificationsByUnity(chartPeriods),
        getNotificationsAged(chartPeriods),
        getNotificationsGeo(chartPeriods),
        getTraps(chartPeriods, webVersion)
      ];
      
      Promise.all(promises)
        .then(([responseChart, responseByUnit, responseAged, responseGeo, responseTraps]) => {
          setLoadingData(false);
          setDataToChart(responseChart);
      
          setLoadingDataByUnit(false);
          setDataByUnity(responseByUnit);
      
          setLoadingDataAged(false);
          setDataAged(responseAged);
      
          setLoadingDataGeoRef(false);
          setDataGeoRef(responseGeo);
      
          setTrapMapInfo(responseTraps);
          setLoadingTrapMap(false);
        })
        .catch(error => {
          console.error('Erro ao obter dados:', error);
        });
      
    

      getSinanUploadHistory();
    }
    else{
      if ((year && year.length == 0) || !year) {
        let beginDate = parseInt(moment(startDate).format("YYYY")) - yearsQuantity;
        let finalDate = parseInt(moment(endDate).format("YYYY"));
        for (let i = beginDate; i <= finalDate; i++) {
          yearsToFilter.push(i.toString());
        }
      }
      var itemGraficPeriods = localStorage.getItem("graficCharts:" + organizationId);

      getSinanUploadHistory(yearsToFilter).then((responseYears) => {
        var sinanHistory = localStorage.getItem("sinanHistoricUpload:" + organizationId) != "" ? JSON.parse(localStorage.getItem("sinanHistoricUpload:" + organizationId)) : null;
        var yearsAux = [];
        if(sinanHistory) {
          responseYears.map((year) => {
            if(year){
              var sinanHistoryObj = sinanHistory.filter(o => o.year == year.year);
              var dateHistory = sinanHistoryObj && sinanHistoryObj.length > 0 ? sinanHistoryObj[0].date : null;
              var yearDateFormated = new Date(year.data.uploadDate);
              var historyDateFormated = new Date(dateHistory);
              if(historyDateFormated < yearDateFormated){
                yearsAux.push(year.year)
              } 
            }
          })
        }
        if(yearsAux.length > 0){
          yearsToFilter = yearsAux;
        } else {
          yearsToFilter = []
        }
        var ciphertext = "";
        var decryptedText = "";
        if(itemGraficPeriods == null || !sinanHistory ||  yearsAux.length > 0){
          getNotificationsPerYear(yearsToFilter).then((response) => {
            setLoadingGraficData(false);

            var label = Array();
            for(var c = 0; c < response.length; c++){
              label.push(response[c].label);
            }

            var labelStorage = localStorage.getItem("label:" + organizationId) != "" ? JSON.parse(localStorage.getItem("label:" + organizationId)) : null;

            if(itemGraficPeriods){
              itemGraficPeriods = CryptoJS.AES.decrypt(itemGraficPeriods, "cripVITEC").toString(CryptoJS.enc.Utf8);
              itemGraficPeriods = JSON.parse(itemGraficPeriods);

              response.map((newInformationGrafic) => {
                if(labelStorage && newInformationGrafic.label){
                  for(var c = 0; c < labelStorage.length; c++){
                    if(newInformationGrafic.label == labelStorage[c]){
                      itemGraficPeriods[c] = newInformationGrafic;
                    }
                  }
                } 
              })
            }

            var updateYears = null;
            if(!sinanHistory || (sinanHistory && sinanHistory.length == 0)){
              updateYears = label.map((labelYear) => {
                return {year: labelYear, date: new Date()}
              })
            } else {
              yearsToFilter.map((year) => {
                sinanHistory.map((yearHistory, index) => {
                  if(yearHistory.year == year){
                    yearHistory.date = new Date();
                  }
                })
              })
            }
            

            if(itemGraficPeriods){
              if(labelStorage){
                itemGraficPeriods.map((itemGrafic, index) => {
                  itemGrafic.label = labelStorage[index];
                })
              }

              setGraficToChart(itemGraficPeriods);
              ciphertext = CryptoJS.AES.encrypt(JSON.stringify(itemGraficPeriods), "cripVITEC").toString();
              localStorage.setItem("graficCharts:" + organizationId, ciphertext)
            } else {
              if(labelStorage){
                itemGraficPeriods.map((itemGrafic, index) => {
                  itemGrafic.label = labelStorage[index];
                })
              }

              setGraficToChart(response);
              ciphertext = CryptoJS.AES.encrypt(JSON.stringify(response), "cripVITEC").toString();
              localStorage.setItem("graficCharts:" + organizationId, ciphertext);
            }
            
            if(!updateYears){
              localStorage.setItem("sinanHistoricUpload:" + organizationId, JSON.stringify(sinanHistory));
            } else{
              localStorage.setItem("sinanHistoricUpload:" + organizationId, JSON.stringify(updateYears))
            }
            
            if(!labelStorage){
              localStorage.setItem("label:" + organizationId, JSON.stringify(label));
            }

            
          });       
        } 
        else{
          itemGraficPeriods = CryptoJS.AES.decrypt(itemGraficPeriods, "cripVITEC").toString(CryptoJS.enc.Utf8);
          itemGraficPeriods = JSON.parse(itemGraficPeriods)
          var labelStorage = localStorage.getItem("label:" + organizationId) != "" ? JSON.parse(localStorage.getItem("label:" + organizationId)) : null;

          itemGraficPeriods.map((itemGrafic, index) => {
            itemGrafic.label = labelStorage[index];
          })

          setLoadingGraficData(false);
          setGraficToChart(itemGraficPeriods);
        }
      })     
    }
  }

  const handleClick  = async (dataKey) => {
    var dataaux = graficToChart;
    dataaux[dataKey].checked = !dataaux[dataKey].checked;
    setGraficToChart(dataaux);
    setChangedGraficToChart(!changedGraficToChart);
    
    return graficToChart;
  };

  async function getTraps(period, webVersion) {
    const finalDateDoUltimo = period[0].periods[period[0].periods.length - 1].finalDate;

    const daysToSubtract = 15;
    const finalDateSubtractFifteen = new Date(finalDateDoUltimo);
    finalDateSubtractFifteen.setDate(finalDateSubtractFifteen.getDate() - daysToSubtract);

    const beginDateDoPrimeiro = period[0].periods[0].beginDate;

    const beginDateSubtractFifteen = new Date(beginDateDoPrimeiro);
    beginDateSubtractFifteen.setDate(beginDateSubtractFifteen.getDate() - daysToSubtract);


    return getTrapsTypes().then(async response => {
      var promisesTraps = response.data.map(async (trapType) => {
        var trapTypeName = trapType.name;

        const filterMap = {
          demandId: "00000000-0000-0000-0000-000000000000",
          organizationId: getUserData("organizationId"),
          period: {
            finalDate: finalDateSubtractFifteen,
            beginDate: beginDateSubtractFifteen,
          },
          territorializations: [],
          trapTypeId: trapType.id, 
          usersIds: [],
        }

        await getActiveTrapsToMap(filterMap)

        try {
          const response = await getActiveTrapsToMap(filterMap);
          return {data: response.data, trapType: trapTypeName};
        } catch (error) {
          console.error(error);
        }
      });

      
      var trapsToMap = await Promise.all(promisesTraps);

      return trapsToMap;
      // if (response.data.length > 0) {
      //   let index = 0;
      //   if(webVersion === "normal_version") {
      //     index = response.data.findIndex(tpType => tpType.name === 'armadilhaOvos');
      //   } else {
      //     index = response.data.findIndex(tpType => tpType.name === 'armadilhaDisseminadoraInseticida');
      //   }
      //   if(index !== -1){
      //     const filterMap = {
      //       demandId: "00000000-0000-0000-0000-000000000000",
      //       organizationId: getUserData("organizationId"),
      //       period: {
      //         finalDate: finalDateSubtractFifteen,
      //         beginDate: beginDateSubtractFifteen,
      //       },
      //       territorializations: [],
      //       trapTypeId: response.data[index].id, // Ovitrampa
      //       usersIds: [],
      //     }

      //     try {
      //       return await getActiveTrapsToMap(filterMap);
      //     } catch (error) {
      //       console.error(error);
      //     }
      //   }
      // }
    });  
  }

  async function getNotificationsGeo(period) {
    const reqNotificationsGeoRef = await getNotificationsGeoRef(period);
    return reqNotificationsGeoRef.data;
  }

  async function changeTypeDate(typeDate) {
    setTypeDateFilter(typeDate);
  }

  async function getNotificationsByUnity(period) {
    const reqNotificationNumbers = await getNotificationsByReportingUnity(period);
    const notificationNumbers = reqNotificationNumbers.data;

    const unity = await getUnidadesNotificadoras();
    const arr_unity = [];
    let arr_aux = [];

    unity.data.forEach(unity => {
      arr_unity[unity.number] = unity.name;
      arr_aux.push({ label: unity.name, value: unity.number });
    });

    setUnityOptions(arr_aux);
    return notificationNumbers;
  }

  async function getNotificationsPerYear(years){
    graficPeriods.filter(g => years.includes(g.year))

    var response = graficPeriods.map(async (period, index) => {
      // graficPeriods[index].year = years[years.length - (index + 1)];
      graficPeriods[index].years = [graficPeriods[index].year];
      if (unitySelected != null)
        graficPeriods[index].unityFilter = unitySelected.value;
      graficPeriods[index].typeDateFilter = typeDateFilter;
      graficPeriods[index].organizationId = getUserData("organizationId");
      graficPeriods[index].desease = selectedDesease;
      graficPeriods[index].ageInterval = 5;
      graficPeriods[index].ageMaximum = 85;
      graficPeriods[index].territorializations = selectedTerritorializations.map(({ value }) => value);

    var responseFunc = await Promise.all([
        getNotificationsToChart([period], true, index).then(response => {
          var data = {};
          // setLoadingGraficData(false);
          var label = new Array();
          for(var c = 0; c < response.length; c++){
            label.push(response[c].label);
          }
  
          data.label = label;
          data.todayEpidemiologicalWeek = todayEpidemiologicalWeek;
          data.graficCharts = response;
  
          setGraficNotificationsYears(graficNotificationsYears.push(data));
        })
      ]).then(() => {
        
      })
      return responseFunc;
      
    });

    return Promise.all(response).then(() => {
      var graficToChartAux = [];
      graficNotificationsYears.map(notificationObj => {
        graficToChartAux.push(notificationObj.graficCharts[0]);
      })
      return graficToChartAux.sort((a, b) => parseInt(a.label, 10) - parseInt(b.label, 10)).reverse();
    })
  }

  async function getNotificationsToChart(period, isGrafic = false, indexOfYear = null) {  
    const reqNotificationNumbers = await getNotificationCases(period);
    const notificationNumbers = reqNotificationNumbers.data;

    if(isGrafic == false){
      for (const index in notificationNumbers) {
        notificationNumbers[index].label = chartPeriods[index].year;
      }
    }
    else{
      for (const index in notificationNumbers) {
        if(indexOfYear){
          notificationNumbers[index].label = graficPeriods[indexOfYear].year;
        } else{
          notificationNumbers[index].label = graficPeriods[index].year;
        }
      }
    }
    return notificationNumbers;
  }

  async function getNotificationsAged(period) {
    const reqNotificationNumbers = await getNotificationNumbersAged(period);
    const notificationNumbers = reqNotificationNumbers.data;
    return notificationNumbers;
  }

  async function getSinanUploadHistory(years = null) {
    let reqSinanHistory = null;
    if(years){
      reqSinanHistory = years.map(async year => await getUploadHistory(selectedDesease, year));
    } else {
      reqSinanHistory = await getUploadHistory(selectedDesease, new Date().getFullYear().toString());

      if (reqSinanHistory && reqSinanHistory.data.length == 1) {
        const SinanHistory = reqSinanHistory.data[0];
        SinanHistory.uploadDate = new Date(toISOFormat(SinanHistory.uploadDate, true));
        SinanHistory.diffDays = await daysBetweenTwoDates(new Date(), SinanHistory.uploadDate);
        SinanHistory.displayUploadDate = moment(SinanHistory.uploadDate).subtract(3, 'hours').format('DD/MM/YYYY - HH:mm')
        setSinanHistory(SinanHistory);
      }

      return reqSinanHistory;
    }
    
    return Promise.all(reqSinanHistory).then((response) => {
      var responseAux = response.map((r) => {
        var sinanHistory = r.data[0];
        var diffDays = null;
        if(sinanHistory){
          diffDays = daysBetweenTwoDates(new Date(), sinanHistory.uploadDate);
          return {year: r.config.url.slice(-4), data: sinanHistory, diffDays: diffDays};
        }
      })
      return responseAux;
    })
  }

  const styles = {
    grid: {
      paddingLeft: 0,
      paddingRight: 0
    },
    row: {
      marginLeft: 0,
      marginRight: 0
    },
    col: {
      paddingLeft: 0,
      paddingRight: "0.2vw"
    }
  };

  async function applyFilter(isButton = false) {
    if(isButton){
      setFirstApplyFilter(false);
    }
    if (year.length > 0) {
      await buildYearsLabelsAndPeriods(year);
    } else {
      await buildLabelsAndPeriods(startDate, endDate);
      if(isButton == false){
        await buildLabelsAndPeriods(startDate, endDate, true);
      }
    }
  }

  async function buildYearsLabelsAndPeriods(years = new Array()) {
    const labels = new Array();
    const periods = new Array();


    if (months.length === 0 && weeks.length === 0) {
      for (let i = 1; i <= 53; i++) {
        labels.push(i);
      }

      for (let i = 0; i < years.length; i++) {
        const epidemiologicalWeek = getWeeks().filter(ew => ew.year === years[i].value);
        periods.push({ year: years[i].value, periods: epidemiologicalWeek[0].weeks.map((ew) => { return { finalDate: moment(ew.endDate, "DD/MM/YYYY").format("YYYY-MM-DD"), beginDate: moment(ew.beginDate, "DD/MM/YYYY").format("YYYY-MM-DD") } }) });
      }
    } else if (weeks.length === 0) {
      months.forEach(month => {
        labels.push(month.label);
      })

      for (let i = 0; i < years.length; i++) {
        const monthPeriods = [];

        for (let j = 0; j < months.length; j++) {
          const beginDate = moment(new Date(years[i].value, months[j].value, 1)).format("YYYY-MM-DD");
          const finalDate = moment(beginDate).add(1, 'month').subtract(1, 'day').format("YYYY-MM-DD");
          monthPeriods.push({ beginDate, finalDate });
        }

        periods.push({ year: years[i].value, periods: monthPeriods });
      }
    } else {
      weeks.forEach(weeks => {
        labels.push(weeks.label);
      })

      for (let i = 0; i < years.length; i++) {
        const weeksPeriods = [];
        const epiWeeks = getWeeks().filter(ew => ew.year === years[i].value);

        for (let j = 0; j < weeks.length; j++) {
          if (epiWeeks[0].weeks[weeks[j].value] !== undefined) {
            const beginDate = moment(epiWeeks[0].weeks[weeks[j].value].beginDate, "DD/MM/YYYY").format("YYYY-MM-DD");
            const finalDate = moment(epiWeeks[0].weeks[weeks[j].value].endDate, "DD/MM/YYYY").format("YYYY-MM-DD");
            weeksPeriods.push({ beginDate, finalDate });
          }
        }
        periods.push({ year: years[i].value, periods: weeksPeriods });
      }
    }

    setChartPeriods(periods);
    setChartLabels(labels);

  }

  async function buildLabelsAndPeriods(startDate, endDate, isGrafic = false) {
    let labelsToCharts = new Array();    

    if(!isGrafic){
      const labels = await buildEpidemiologicalLabels({ startDate, endDate }); //semana epidemiológica
      const periods = new Array({ year: "", periods: [] });

      for (let i = 0; i < labels.length; i++) {
        for (let labelIndex = 0; labelIndex < labels[i].labels.length; labelIndex++) {
          const { finalDate, beginDate } = await getEpidemiologicalPeriod({ labels: labels[i].labels, labelIndex, startDate, endDate, year: labels[i].year, lastYear: labels[labels.length - 1].year, firstYear: labels[0].year }); //semana epidemiológica
          labelsToCharts.push(labels[i].labels[labelIndex] + "/" + labels[i].year);
  
          periods[0].periods.push({ finalDate, beginDate });
        }
      }

      setChartPeriods(periods);
      setChartLabels(labelsToCharts);
    }
    else{
      var yearTemp = endDate[0]+endDate[1]+endDate[2]+endDate[3];

      const labels = new Array();
      const periods = new Array();
      const years = new Array();

      for(var c = 0; c < yearsQuantity; c++){
        years.push((yearTemp - c).toString());
      }

      for (let i = 1; i <= 53; i++) {
        labels.push(i);
      }

      for (let i = 0; i < years.length; i++) {
        const epidemiologicalWeek = getWeeks().filter(ew => ew.year === years[i]);
        periods.push({ year: years[i], periods: epidemiologicalWeek[0].weeks.map((ew) => { return { finalDate: moment(ew.endDate, "DD/MM/YYYY").format("YYYY-MM-DD"), beginDate: moment(ew.beginDate, "DD/MM/YYYY").format("YYYY-MM-DD") } }) });
      }
      
      setGraficPeriods(periods);
      setGraficLabels(labels);
    }
    
  }

  function changePeriod(e, field) {
    switch (field) {
      case 'Year':
        if (e === null) {
          setFilterYear([]);
          setFilterMonths([]);
          setFilterWeeks([]);
          break;
        } else {
          e = e.sort((a, b) => parseInt(a.value) - parseInt(b.value));
        }

        setFilterYear(e !== null ? e : []);
        break;

      case 'Month':
        if (e !== null) {
          setFilterWeeks([]);
          e = e.sort((a, b) => parseInt(a.value) - parseInt(b.value));
        }

        setFilterMonths(e !== null ? e : []);
        break;

      case 'EpiWeek':
        if (e !== null) {
          setFilterMonths([]);
          e = e.sort((a, b) => parseInt(a.value) - parseInt(b.value));
        }
        setFilterWeeks(e !== null ? e : []);
        break;

      case 'Week1':
        if (e !== null) {
          setFilterMonths([]);
        }
        setWeekValue1(e !== null ? e : []);
        break;

      case 'Week2':
        if (e !== null) {
          setFilterMonths([]);
        }
        setWeekValue2(e !== null ? e : []);
        break;

    }
  }

  function handleChangeFilter(field, value) {
    switch (field) {
      case "territorialization":
        if (value !== null) {
          //colocando as territorializações para ficarem selecionadas no filtro.
          setSelectedTerritorializations(value);
        } else {
          setSelectedTerritorializations([]);
        }
        break;
    }
  }

  return (
    <>
      {/* Page content */}

      <Container fluid className="bg-gradient-info pt-5 pb-6 pt-md-8">
        <Row style={styles.row} className="mt-4 mb-2">
          <Col style={styles.col} xl="2">
            <NotificationYearContainer
              data={dataToCard}
            />
          </Col>
          <Col style={styles.col} xl="2">
            <NotificationMonthContainer
              data={dataToCard}
            />
          </Col>
          <Col style={styles.col} xl="2">
            <NotificationWeekContainer
              data={dataToCard}
            />
          </Col>
          <Col style={styles.col} xl="2">
            <ConfirmedYearContainer
              data={dataToCard}
            />
          </Col>
          <Col style={styles.col} xl="2">
            <ConfirmedMonthContainer
              data={dataToCard}
            />
          </Col>
          <Col style={styles.col} xl="2">
            <ConfirmedWeekContainer
              data={dataToCard}
            />
          </Col>
        </Row>

        <Container className="filter-container">
          <button className="btn btn-primary hide-btn" onClick={() => { setFilterVisibility(!isFilterVisible) }}>
            {isFilterVisible ? "Esconder Filtro" : "Mostrar Filtro"}
          </button>

          {isFilterVisible ?
            <button className="btn btn-primary filter-btn" onClick={() => applyFilter(true)}>Filtrar</button>
            : null}
        </Container>

        {isFilterVisible &&
          <>
            <Row>
              <MainPeriodComponent
                months={months}
                years={year}
                weeks={weeks}
                weekValue1={weekValue1}
                weekValue2={weekValue2}
                showWeekToWeek
                changePeriod={changePeriod}
              />
              <SinanFilterPresentational
                startDate={startDate}
                endDate={endDate}
                selectedDesease={selectedDesease}
                changeDesease={setSelectedDesease}
                changeStartDate={setStartDate}
                changeEndDate={setEndDate}
                changeTypeDate={changeTypeDate}
                selectedTypeDate={typeDateFilter}
              />

              <GeographicFilterPresentational
                Territorializations={territorializationList}
                handleChangeFilter={handleChangeFilter}
                SelectedTerritorializations={selectedTerritorializations}
              />

              {/* <Col className="col-4">
            </Col> */}
              <UnitFilter
                unityOptions={unityOptions}
                unitySelected={unitySelected}
                changeUnitSelected={setUnitySelected}
              />
              <TodayEpidemiologicalWeekComponent 
                todayEpidemiologicalWeek={todayEpidemiologicalWeek}
              />
              <Col className="col-4 linha-form col-md-4 geo-responsive-filter col" style={{ display: "flex", alignItems: "center" }}>
                <div>
                  <div data-tip data-for="validateDemandTooltip">
                    <span className="h4 text-white">Data do último envio: </span><span style={sinanHistory.diffDays <= 1 ? { color: "#35fd35", fontWeight: 'bolder' } : sinanHistory.diffDays > 1 && sinanHistory.diffDays < 3 ? { color: "yellow", fontWeight: 'bolder' } : { color: "red", fontWeight: 'bolder' }}>{sinanHistory.displayUploadDate}</span>
                  </div>
                  <span className="h4 text-white">Status: <span style={{
                    color:
                      sinanHistory.status == 'Processado' ? '#35fd35' : 'yellow'
                  }}>{sinanHistory.status}</span></span>

                  <ReactTooltip effect="solid" type={sinanHistory.diffDays <= 1 ? 'success' : sinanHistory.diffDays > 1 && sinanHistory.diffDays < 3 ? 'warning' : 'error'} id="validateDemandTooltip">
                    <span style={{ fontWeight: 'bolder' }}>{sinanHistory.diffDays} dia(s) sem atualização</span>
                  </ReactTooltip>
                </div>
              </Col>
            </Row>
          </>
        }
      </Container>
      <Container fluid className="mt--5">
        <Row>
        <Col className="col-6 mb-4 responsive-field-sinan-medium">
          {/* {
            loadingDataGeoRef || loadingTerritorializationRequest || loadingTrapMap
            ?
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <LoadingSpin primaryColor="#fff" size={28} />
              </div>
            :
            <SinanMapContainer
            observations = {firstApplyFilter == true ? " - Últimos 15 dias" : ""}
            webVersion={webVersion}
            data={dataGeoRef}
            type={"Notificações"}
            trapMapInfo = {trapMapInfo}
            territorializationList={territorializationList}
            territorializationFiltered={selectedTerritorializations}
            />
          } */}
          {
            loadingDataGeoRef || loadingTerritorializationRequest || loadingTrapMap
            ?
              <MapLoadingSkeleton />
            :
            <Suspense fallback={
              <MapLoadingSkeleton />
            }>
              <SinanMapContainer
                observations = {firstApplyFilter == true ? " - Últimos 15 dias" : ""}
                webVersion={webVersion}
                chartPeriods={chartPeriods}
                data={dataGeoRef}
                type={"Notificações"}
                trapMapInfo = {trapMapInfo}
                territorializationList={territorializationList}
                territorializationFiltered={selectedTerritorializations}
              /> 
            </Suspense>
          }
             
          </Col>
          <Col className="col-6 mb-4 responsive-field-sinan-medium">
          {/* {
            // loadingDataGeoRef || loadingTerritorializationRequest || 
            loadingDataGeoRef || loadingTrapMap || loadingTerritorializationRequest
            ?
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>              
                <LoadingSpin primaryColor="#fff" size={28} />              
            </div>
            :
            <SinanMapContainer
              observations = {firstApplyFilter == true ? " - Últimos 15 dias" : ""}
              webVersion={webVersion}
              data={dataGeoRef.filter(d => d.confirmed)}
              type={"Confirmados"}
              trapMapInfo = {trapMapInfo}
              territorializationList={territorializationList}
              territorializationFiltered={selectedTerritorializations}
            />
          } */}
          {
            loadingDataGeoRef || loadingTerritorializationRequest || loadingTrapMap
            ?
              <MapLoadingSkeleton />
            :
            <Suspense fallback={
              <MapLoadingSkeleton />
            }>
              <SinanMapContainer
                observations = {firstApplyFilter == true ? " - Últimos 15 dias" : ""}
                webVersion={webVersion}
                chartPeriods={chartPeriods}
                data={dataGeoRef.filter(d => d.confirmed)}
                type={"Confirmados"}
                trapMapInfo = {trapMapInfo}
                territorializationList={territorializationList}
                territorializationFiltered={selectedTerritorializations}
              />
            </Suspense>
          }
          </Col>

          
          <Col className="col-12 mb-4 responsive-field-sinan-full">
          {
            firstApplyFilter
            ?
              loadingGraficData
              ?
                <MapLoadingSkeleton />
              :
                typeContainer == "serieHistorica"
                ?
                <Suspense fallback={
                  <MapLoadingSkeleton />
                }>
                  <NotificationsByDayHistoricSerieContainer
                    labels={graficLabels}
                    data={graficToChart}
                    todayEpidemiologicalWeek={todayEpidemiologicalWeek}
                    typeContainer={typeContainer}
                    setTypeContainer={setTypeContainer}
                  />
                </Suspense>
                :
                <Suspense fallback={
                  <MapLoadingSkeleton />
                }>
                  <NotificationsByDayContainer
                    labels={graficLabels}
                    data={graficToChart}
                    changedGraficToChart={changedGraficToChart}
                    typeContainer={typeContainer}
                    setTypeContainer={setTypeContainer}
                    handleClick = {handleClick}
                  />
                </Suspense>
            :
              loadingData
              ?
                <MapLoadingSkeleton />
              :
              <Suspense fallback={
                <MapLoadingSkeleton />
              }>
                <NotificationsByDayContainer
                  labels={chartLabels}
                  data={dataToChart}
                />
              </Suspense>
            }
          </Col>
          

          <Col className="col-12 mb-4 responsive-field-sinan-full">
          {
            firstApplyFilter
            ?
              loadingGraficData
              ?
                <MapLoadingSkeleton />
              :
                typeContainerConfirmed == "serieHistorica"
                ?
                <Suspense fallback={
                  <MapLoadingSkeleton />
                }>
                  <ConfirmationsByDayHistoricSerieContainer
                    labels={graficLabels}
                    data={graficToChart}
                    todayEpidemiologicalWeek={todayEpidemiologicalWeek}
                    typeContainer={typeContainerConfirmed}
                    setTypeContainer={setTypeContainerConfirmed}
                  />
                </Suspense>
                :
                <Suspense fallback={
                  <MapLoadingSkeleton />
                }>
                  <ConfirmationsByDayContainer
                    labels={graficLabels}
                    data={graficToChart}
                    changedGraficToChart={changedGraficToChart}
                    typeContainer={typeContainerConfirmed}
                    setTypeContainer={setTypeContainerConfirmed}
                    handleClick = {handleClick}
                  />
                </Suspense>
            :
              loadingData
              ?
                <MapLoadingSkeleton />
              :
              <Suspense fallback={
                <MapLoadingSkeleton />
              }>
                <ConfirmationsByDayContainer
                  labels={chartLabels}
                  data={dataToChart}
                />
              </Suspense>
          }

          </Col>
          <Col className="col-6 mb-4 responsive-field-sinan-medium">
          {
              loadingData
              ?
                <DonutLoadingSkeleton />
              :
              <Suspense fallback={
                <DonutLoadingSkeleton />
              }>
                <GenderNotificationsDonutContainer
                  data={dataToChart}
                />
              </Suspense>
              }
          
              </Col>
          
          
          <Col className="col-6 mb-4 responsive-field-sinan-medium" >
          {
              loadingData
              ?
                <DonutLoadingSkeleton />
              :
              <Suspense fallback={
                <DonutLoadingSkeleton />
              }>
                <GenderConfirmedDonutContainer
                  data={dataToChart}
                />
              </Suspense>
          }
          </Col>
          <Col className="col-6 mb-4 responsive-field-sinan-medium" >
          {selectedDesease == "DENGUE" 
          ?
              loadingData
              ?
                <DonutLoadingSkeleton />
              :
              <Suspense fallback={
                <DonutLoadingSkeleton />
              }>
                <SorotypeDonut
                  data={dataToChart}
                />
              </Suspense>
          :
          <>
          </>
          }
          </Col>
          <Col className="col-6 mb-4 responsive-field-sinan-medium">
          {selectedDesease == "DENGUE" 
            ?
                loadingData
                ?
                <DonutLoadingSkeleton />
                :
                <Suspense fallback={
                  <DonutLoadingSkeleton />
                }>
                  <TypeExamDonutContainer
                    data={dataToChart}
                  />
                </Suspense>
            :
            <>
            </>
          }
          </Col>

          {/* Colocar após BackEnd
          <Col className="col-6 mb-4 responsive-field-sinan-medium">
          {selectedDesease == "DENGUE" 
            ?
                loadingData
                ?
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <LoadingSpin primaryColor="#fff" size={28} />
                </div>
                :
                <TypeExamDonutContainer
                  data={dataToChart}
                  typeDonut={"allExams"}
                />
            :
            <>
            </>
            }
          </Col>
          <Col className="col-6 mb-4 responsive-field-sinan-medium">
          {selectedDesease == "DENGUE" 
          ?
              loadingData
              ?
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <LoadingSpin primaryColor="#fff" size={28} />
              </div>
              :
              <TypeExamDonutContainer
                data={dataToChart}
                typeDonut={"positives"}
              />
          :
          <>
          </>
          }
          </Col> 
          */}

          <Col className="col-6 mb-4 responsive-field-sinan-medium">
            {
              loadingData
                ?
                  <VerticalBarGraphicLoadingSkeleton />
                :
                <Suspense fallback={
                  <VerticalBarGraphicLoadingSkeleton />
                }>
                  <GenderNotificationsBar
                    data={dataToChart}
                  />
                </Suspense>
            }
          </Col>
          
          <Col className="col-6 mb-4 responsive-field-sinan-medium">
          {
              loadingData
                ?
                  <VerticalBarGraphicLoadingSkeleton />
                :
                <Suspense fallback={
                  <VerticalBarGraphicLoadingSkeleton />
                }>
                  <GenderConfirmedBar
                    data={dataToChart}
                  />
                </Suspense>
          }
          </Col>
          <Col className="col-6 mb-4 responsive-field-sinan-medium">
          {
              loadingDataAged
                ?
                  <PyramidGraphicLoadingSkeleton />
                :
                <Suspense fallback={
                  <PyramidGraphicLoadingSkeleton />
                }>
                  <NotificationAgedContainer
                    data={dataAged}
                  />
                </Suspense>
          }
          </Col>
          <Col className="col-6 mb-4 responsive-field-sinan-medium">
          {
              loadingDataAged
                ?
                  <PyramidGraphicLoadingSkeleton />
                :
                <Suspense fallback={
                  <PyramidGraphicLoadingSkeleton />
                }>
                  <ConfirmedAgedContainer
                    data={dataAged}
                  />
                </Suspense>
          }
          </Col>
          <Col className="col-12 mb-4 responsive-field-sinan-full">
          {
              loadingDataByUnit
                ?
                  <HorizontalBarGraphicLoadingSkeleton />
                :
                <Suspense fallback={
                  <HorizontalBarGraphicLoadingSkeleton />
                }>
                <ReportingUnityContainer
                  data={dataByUnity}
                />
                </Suspense>
          }
          </Col>
        </Row>
      </Container>
    </>
  );
};

const SinanMapContainer = React.lazy(() => import("../components/Containers/Sinan/Maps/SinanMapContainer"));
const NotificationsByDayHistoricSerieContainer = React.lazy(() => import("../components/Containers/Sinan/Charts/Line/NotificationsByDayHistoricSerieContainer"));
const NotificationsByDayContainer = React.lazy(() => import("../components/Containers/Sinan/Charts/Line/NotificationsByDayContainer"));
const ConfirmationsByDayHistoricSerieContainer = React.lazy(() => import("../components/Containers/Sinan/Charts/Line/ConfirmationsByDayHistoricSerieContainer"));
const ConfirmationsByDayContainer = React.lazy(() => import("../components/Containers/Sinan/Charts/Line/ConfirmationsByDayContainer"));
const GenderNotificationsDonutContainer = React.lazy(() => import("../components/Containers/Sinan/Charts/Donuts/GenderNotificationsDonutContainer"));
const GenderConfirmedDonutContainer = React.lazy(() => import("../components/Containers/Sinan/Charts/Donuts/GenderConfirmedDonutContainer"));
const SorotypeDonut = React.lazy(() => import("../components/Containers/Sinan/Charts/Donuts/SorotypeDonut"));
const TypeExamDonutContainer = React.lazy(() => import("../components/Containers/Sinan/Charts/Donuts/TypeExamDonutContainer"));
const GenderNotificationsBar = React.lazy(() => import("../components/Containers/Sinan/Charts/VerticalBar/GenderNotificationsBar"));
const GenderConfirmedBar = React.lazy(() => import("../components/Containers/Sinan/Charts/VerticalBar/GenderConfirmedBar"));
const NotificationAgedContainer = React.lazy(() => import("../components/Containers/Sinan/Charts/HorizontalBar/NotificationAgedContainer"));
const ConfirmedAgedContainer = React.lazy(() => import("../components/Containers/Sinan/Charts/HorizontalBar/ConfirmedAgedContainer"));
const ReportingUnityContainer = React.lazy(() => import("../components/Containers/Sinan/Charts/HorizontalBar/ReportingUnityContainer"));

export default DashboardSinan;
